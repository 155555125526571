
import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import "typeface-muli"

import Header from "./header"
import Footer from "./footer"
import SubHeader from "./sub-header"
import GlobalStyles from "./global-styles.js"
import { innerStyles } from "../utils/common-styles"

const Container = styled.div`
  ${innerStyles}
`

const LayoutPage = ({ children, path, title, subtitle }) => (
  <>
    <Header path={path} />
      <SubHeader
        title={title}
        subtitle={subtitle}
      />
      <Container>
        <main>{children}</main>
      </Container>
      <Footer />
    <GlobalStyles />
  </>
)

LayoutPage.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string
}

export default LayoutPage
